export enum RewardTypeEnum {
    CRYPTO_CURRENCY = 'crypto_currency',
    OTHER = 'other',
}

export enum OtherMissionRewardSubtypeEnum {
    NFT = 'nft',
    BONUS = 'bonus',
    TANGIBLE = 'tangible',
    SUBSCRIPTION = 'subscription',
    WHITELIST = 'whitelist',
    DISCOUNT = 'discount',
    OTHER = 'other'
}

export enum QuestQueryParamsEnum {
    TYPE = 'type',
}

export enum SocialRedirectPageEnum {
    SETTINGS = 'settings',
    MISSION = 'mission',
}

export enum SocialAccountQueryParamsEnum {
    SOCIAL_CONNECTED = 'social_connected',
    IS_PRIMARY = 'is_primary',
    SOCIAL_NETWORK = 'social_network',
    ERROR = 'error',
}

export enum SocialAccountConnectErrorType {
    INVALID_REDIRECT = 'invalid_redirect',
    SUSPENDED_ACCOUNT = 'suspended_account',
    DUPLICATE = 'duplicate',
    GENERIC_ERROR = 'generic_error',
}

export enum ReportTypeEnum {
    QUEST_GUILD = 'QuestGuild',
    QUEST_MISSION = 'QuestMission',
    QUEST_ROUND = 'QuestRound',
    QUEST_ACTION = 'QuestAction',
}
